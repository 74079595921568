import Firebase from "firebase/app";

const getNewTransactionID = () => {
  let now = new Date().getTime();
  let start = new Date(2020, 0o1, 0o1, 0, 0, 0, 0).getTime();
  return parseInt(((now - start) / 1000).toFixed(0));
};

export function startCommand(chargePointId, command, payload) {
  return Firebase.firestore()
    .collection(`ocpp_logs/${chargePointId}/pending_commands`)
    .add({
      id: `${getNewTransactionID()}`,
      timestamp: new Date(),
      command: command,
      payload: payload,
      ocpi: false,
    })
    .then((doc) => doc.id)
    .catch((error) => {
      alert(`Unable to initiate command: ${error.message}`);
    });
}

export function cancelCommand(chargePointId, id) {
  let doc = Firebase.firestore()
    .collection(`ocpp_logs/${chargePointId}/pending_commands`)
    .doc(id)
    .delete();
}
